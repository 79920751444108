<template>
    <div class="content-wrapper">
        <!--Хлебные крошки-->
        <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
        <!---->
        <div class="content">
            <SpinnerLoader v-if="loading" />
            <div v-else>
                <v-row class="ma-0" justify="space-between" align="center">
                    <h1 class="dashboard">Гибкая настройка прав доступа</h1>
                    <v-btn
                        elevation="0"
                        color="main"
                        class="white--text"
                        @click="saveSettings"
                        :disabled="!checkRole(project.id, 'project:rolesSettings:save')"
                        :loading="saveSettingsLoading"
                    >
                        Сохранить
                    </v-btn>
                </v-row>
                <div
                    class="settings-group"
                    v-for="settingGroup in availableSettingsList"
                    :style="`margin-left: ${24 * settingGroup.nestingLevel}px;`"
                    :class="{ 'setting-group__nested': settingGroup.nestingLevel }"
                    :key="settingGroup.id"
                >
                    <h2 class="dashboard mt-6">
                        {{ settingGroup.title }}
                    </h2>

                    <div class="my-4" v-for="setting in settingGroup.list" :key="setting.id">
                        <div class="setting">
                            <span class="setting-label mr-12">{{ setting.label }}</span>
                            <div class="checkboxes">
                                <v-checkbox
                                    v-for="role in orderedRoles"
                                    :key="role.id"
                                    :disabled="
                                        role.id === 'owner' ||
                                            !checkRole(project.id, 'project:rolesSettings:save')
                                    "
                                    hide-details
                                    :label="role.name"
                                    v-model="settings[setting.id].access[role.id]"
                                ></v-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import Breadcrumbs from '../components/Breadcrumbs.vue'
import Select from '../components/inputs/Select.vue'
import { getCurrentSettings, getSettingsList, saveRoleSettings, saveSettings } from '@/api/projects'
import SpinnerLoader from '../components/SpinnerLoader.vue'
import { CONNECTOR_TYPES } from '../vars/general'
import { checkRole } from '../helpers/roles'

const hasGcb2Connector = project =>
    Boolean(project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2))
export default {
    name: 'Project',
    components: { Breadcrumbs, Select, SpinnerLoader },
    data: () => ({
        settings: {},
        loading: true,
        saveSettingsLoading: false,
    }),

    computed: {
        ...mapState(['roles']),
        ...mapGetters(['projectById']),
        project() {
            const projectId = this.$router.currentRoute.params.id
            return this.$store.getters.projectById(projectId)
        },
        orderedRoles() {
            return _.sortBy(this.roles, 'power').reverse()
        },
        breadcrumbs() {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: 'HR платформа Точек красоты',
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'Гибкая настройка прав доступа',
                    to: '',
                    disabled: true,
                },
            ]
        },
        settingsList() {
            return [
                {
                    id: 'project',
                    title: 'Проект в целом',
                    nestingLevel: 0,
                    list: [
                        {
                            id: 'project:rename',
                            label: 'Переименование проекта',
                        },
                        {
                            id: 'project:changeImage',
                            label: 'Изменение картинки проекта',
                        },
                        {
                            id: 'project:delete',
                            label: 'Удаление',
                        },
                    ],
                },
                {
                    id: 'project:settings',
                    title: 'Настройки',
                    nestingLevel: 1,
                    list: [
                        {
                            id: 'project:settings:view',
                            label: 'Просмотр',
                        },
                        {
                            id: 'project:settings:save',
                            label: 'Редактирование',
                        },
                    ],
                },
                {
                    id: 'project:communicationUpdate',
                    title: 'Обновление расчетов',
                    nestingLevel: 0,
                    list: [
                        {
                            id: 'project:communicationUpdate',
                            label: 'Включение',
                        },
                    ],
                },

                {
                    id: 'gcb2:activation',
                    title: 'Активации',
                    nestingLevel: 0,
                    list: [
                        {
                            id: 'gcb2:activation:edit',
                            label: 'Редактирование',
                        },
                        {
                            id: 'gcb2:activation:audience:edit',
                            label: 'Редактирование аудиторий',
                        },
                        {
                            id: 'gcb2:activation:toogleIsRunning',
                            label: 'Включение/выключение',
                        },
                        {
                            id: 'gcb2:activation:delete',
                            label: 'Удаление',
                        },
                    ],
                },

                {
                    id: 'gcb2',
                    title: 'Кого из кандидатов стоит позвать',
                    nestingLevel: 0,
                    list: [],
                },
                {
                    id: 'gcb2:bot',
                    title: 'Вкладка «Бот»',
                    nestingLevel: 1,
                    list: [
                        {
                            id: 'gcb2:bot:toogleIsRunning',
                            label: 'Включение/выключение триггеров',
                        },
                    ],
                },
                {
                    id: 'gcb2:tasklist',
                    title: 'Вкладка «Список задач»',
                    nestingLevel: 1,
                    list: [
                        {
                            id: 'gcb2:tasklist:view',
                            label: 'Просмотр',
                        },
                    ],
                },
                {
                    id: 'gcb2:tasklist:moda:general',
                    title: 'Цикл возврата общий',
                    nestingLevel: 2,
                    list: [
                        {
                            id: 'gcb2:tasklist:moda:general:view',
                            label: 'Просмотр',
                        },
                        {
                            id: 'gcb2:tasklist:moda:general:save',
                            label: 'Редактирование',
                        },
                    ],
                },
                {
                    id: 'gcb2:tasklist:moda:personal',
                    title: 'Цикл возврата персональный',
                    nestingLevel: 2,
                    list: [
                        {
                            id: 'gcb2:tasklist:moda:personal:view',
                            label: 'Просмотр',
                        },
                        {
                            id: 'gcb2:tasklist:moda:personal:save',
                            label: 'Редактирование',
                        },
                    ],
                },
                {
                    id: 'gcb2:tasklist:action:dontContact',
                    title: 'Кнопка «Не связались»',
                    nestingLevel: 2,
                    list: [
                        {
                            id: 'gcb2:tasklist:action:dontContact:view',
                            label: 'Просмотр',
                        },
                    ],
                },
                {
                    id: 'gcb2:tasklist:massSend',
                    title: 'Пакетная обработка',
                    nestingLevel: 2,
                    list: [
                        {
                            id: 'gcb2:tasklist:massSend:view',
                            label: 'Отображать кнопку',
                        },
                    ],
                },
                {
                    id: 'gcb2:tasklist:clientphones',
                    title: 'Телефоны клиентов',
                    nestingLevel: 2,
                    list: [],
                },
                {
                    id: 'gcb2:tasklist:clientPhone:table',
                    title: 'В списке задач',
                    nestingLevel: 3,
                    list: [
                        {
                            id: 'gcb2:tasklist:clientPhone:table:view',
                            label: 'Просмотр',
                        },
                    ],
                },
                {
                    id: 'gcb2:tasklist:clientPhone:sidebar',
                    title: 'В сайдбаре',
                    nestingLevel: 3,
                    list: [
                        {
                            id: 'gcb2:tasklist:clientPhone:sidebar:view',
                            label: 'Просмотр',
                        },
                    ],
                },
                {
                    id: 'gcb2:analytics',
                    title: 'Вкладка «Аналитика»',
                    nestingLevel: 1,
                    list: [
                        {
                            id: 'gcb2:analytics:view',
                            label: 'Просмотр',
                        },
                    ],
                },
                {
                    id: 'returnability',
                    title: 'Обзор удержания клиентов',
                    nestingLevel: 0,
                    list: [
                        {
                            id: 'returnability:view',
                            label: 'Просмотр',
                        },
                    ],
                },
                {
                    id: 'marketingReport',
                    title: 'Когортный анализ',
                    nestingLevel: 0,
                    list: [
                        {
                            id: 'marketingReport:view',
                            label: 'Просмотр',
                        },
                    ],
                },

                {
                    id: 'gcb2:churnReport',
                    title: 'Отчет «После каких сотрудников уходят клиенты»',
                    nestingLevel: 0,
                    list: [
                        {
                            id: 'gcb2:churnReport:view',
                            label: 'Просмотр',
                        },
                    ],
                },
                {
                    id: 'gcb2:staffReport',
                    title: 'Отчет по мастерам',
                    nestingLevel: 0,
                    list: [
                        {
                            id: 'gcb2:staffReport:view',
                            label: 'Просмотр',
                        },
                    ],
                },
                {
                    id: 'gcb2:sameDayRec',
                    title: 'ОПЗ',
                    nestingLevel: 0,
                    list: [
                        {
                            id: 'gcb2:sameDayRec:view',
                            label: 'Просмотр',
                        },
                    ],
                },
                {
                    id: 'simpleReports',
                    title: 'Общие отчеты',
                    nestingLevel: 0,
                    list: [
                        {
                            id: 'simpleReports:view',
                            label: 'Просмотр',
                        },
                    ],
                },
                {
                    id: 'gcb2:doctorReport',
                    title: 'Отчет «Доктор DH»',
                    nestingLevel: 0,
                    list: [
                        {
                            id: 'gcb2:doctorReport:view',
                            label: 'Просмотр',
                        },
                    ],
                },
                {
                    id: 'gcb2:kpi',
                    title: 'KPI сотрудников',
                    nestingLevel: 0,
                    list: [
                        {
                            id: 'gcb2:kpi:view',
                            label: 'Просмотр',
                        },
                        {
                            id: 'gcb2:kpi:edit',
                            label: 'Редактировние',
                        },
                    ],
                },

                /*           {
                    id: 'gcb2:avgCheckReport',
                    title: 'Отчет по среднему чеку',
                    nestingLevel: 0,
                    list: [
                        {
                            id: 'gcb2:avgCheckReport:view',
                            label: 'Просмотр',
                        },
                    ],
                },
 */
                {
                    id: 'project:rolesSettings',
                    title: 'Гибкая настройка прав доступа',
                    nestingLevel: 0,
                    list: [
                        {
                            id: 'project:rolesSettings:view',
                            label: 'Просмотр',
                        },
                        {
                            id: 'project:rolesSettings:save',
                            label: 'Редактирование',
                        },
                    ],
                },
                {
                    id: 'project:users',
                    title: 'Участники',
                    nestingLevel: 0,
                    list: [
                        {
                            id: 'project:users:view',
                            label: 'Просмотр',
                        },
                        {
                            id: 'project:users:edit',
                            label: 'Редактирование',
                        },
                    ],
                },
                {
                    id: 'project:scheduledReport',
                    title: 'Отправка отчетов',
                    nestingLevel: 0,
                    list: [
                        {
                            id: 'project:scheduledReport:view',
                            label: 'Просмотр',
                        },
                        {
                            id: 'project:scheduledReport:save',
                            label: 'Редактирование',
                        },
                        {
                            id: 'project:scheduledReport:delete',
                            label: 'удаление',
                        },
                    ],
                },
                {
                    id: 'project:connector',
                    title: 'Источники',
                    nestingLevel: 0,
                    list: [
                        {
                            id: 'project:connector:view',
                            label: 'Просмотр',
                        },
                        {
                            id: 'project:connector:edit',
                            label: 'Редактирование',
                        },
                        {
                            id: 'project:connector:delete',
                            label: 'Удаление',
                        },
                    ],
                },
            ]
        },
        availableSettingsList() {
            return this.settingsList
                .filter(el => (el.showCondition ? el.showCondition(this.project) : true))
                .map(settingsGroup =>
                    Object.assign({}, settingsGroup, {
                        list: settingsGroup.list.filter(el =>
                            el.showCondition ? el.showCondition(this.project) : true
                        ),
                    })
                )
        },
    },
    watch: {},
    methods: {
        checkRole,
        async saveSettings() {
            try {
                this.saveSettingsLoading = true
                const diff = []
                for (const key in this.settings) {
                    const setting = this.settings[key]
                    if (!_.isEqual(setting, this.project.rolesSettings[key])) {
                        diff.push({ [key]: setting })
                    }
                }
                await saveRoleSettings({ projectId: this.project.id, settings: diff })
                await this.$store.dispatch('project/fetchProject', this.project.id)
                this.saveSettingsLoading = false
                this.$router.push(`/project/${this.project.id}`)
            } catch (err) {
                console.error(err)
                this.saveSettingsLoading = false
                this.$store.dispatch('callNotify', 'Ошибка при сохранении настроек')
            }
        },
        setSetting(id, value) {
            this.settings = Object.assign({}, this.settings, {
                [id]: value,
            })
        },
    },
    async created() {
        this.loading = true
        this.settings = _.cloneDeep(this.project?.rolesSettings)
        this.loading = false
    },
    mounted() {},
}
</script>
<style lang="sass" scoped>
@import @/vars.sass
.checkboxes
    display: flex
    gap:12px
.setting
    display: flex
    justify-content: space-between
    align-items: center
.setting-label
    width: 500px
    display: inline-flex
    align-items: flex-end
.setting-group__nested
    padding-left: 16px
    border-left: 1px solid $light-gray-4
.setting-input
    width: 600px
.input-disabled
    opacity: 0.5
::v-deep .v-input--selection-controls
    margin-top:0
</style>
